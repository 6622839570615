import { TheCalendar } from "./TheCalendar";
import "./index.css";

export default function App() {
    return (
        <div clssName="App">
        <h1>Bålsta BTKs kalender</h1>
        <TheCalendar />
        </div>
    )
}

