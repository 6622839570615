import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import iCalendarPlugin from '@fullcalendar/icalendar'
import svLocale from '@fullcalendar/core/locales/sv';
import React, { useState } from 'react';
import "./dropdown.css";


export const TheCalendar = () => {
      
 const dropdown_options = [
      {label: 'Alla händelser', value: '?'},
      {label: 'BBTK Allmänt', value: '346428'},
      {label: 'Måndagsgruppen 17:30', value: '359957'},
      {label: 'Måndagsgruppen 18:30', value: '359958'},
      {label: 'Tisdagsgruppen 18:00', value: '359959'},
      {label: 'Torsdagsgruppen 18:00', value: '359960'},
      {label: 'Torsdagsgruppen 19:00', value: '359961'},
      {label: 'Söndagsgruppen 16:00', value: '367200'},
      {label: 'Old boys', value: '365787'},
      {label: 'Pingis Parkson', value: '406224'},
 ]

 const [value, setValue] = useState('?');
 const handleChange = (event) => {setValue([event.target.value])};

 let ids = [
   '346428', '359957', '359958', '359959', '359960', '359961', '367200', '365787','406224' 
 ]


 if (value[0] !== '?') {
   ids = value;
 } 


 const sources = ids.map(p => {
   return {
      url: 'https://dn5b406mck.execute-api.eu-north-1.amazonaws.com/Prod/calendar/' + p,
      format: 'ics',
   }
 });


 function renderEventContent(eventInfo) {
      return (
        <>
		  <p class="cal">
          <b>{eventInfo.timeText === "00:00" ? "" : eventInfo.timeText + ": "}</b>
          
          <i>{eventInfo.event.title}</i>
		  </p>
        </>
      )
 }

 return (
     <div>
      <div>
      <label>
       <select className="dropdown-options" multiple={false} onChange={handleChange}>
         {
             dropdown_options.map((option) => (<option value={option.value}>{option.label}</option>)) 
	     }
       </select>
      </label>
	  <p />
    </div>
      <FullCalendar
        plugins={[iCalendarPlugin, dayGridPlugin]}
        initialView='dayGridMonth'
        weekends={true}
        firstDay={1}
        height='800px'
        locale={svLocale}
        lang='sv'
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
          hour12: false
        }}
        eventSources={sources}
        eventContent={renderEventContent}
      />
    </div>
 );
};

